.osnova{
    display: flex;
    flex-direction: column;
    height: fit-content;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}


.text{
    text-align: center;
    display: flex;
    justify-content: center;
    height: 100px;
    padding: 20px 0; 
}

.text a{
    text-decoration: none;
    font-size: 35px;
    color: var(--tg-theme-text-color);
}

