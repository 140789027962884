*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: verdana; 
}
:root {
    --color-primary: var(--tg-theme-bg-color);
  }       
 body{
    background: var(--color-primary);
    color: var(--tg-theme-text-color);   
}

::-webkit-scrollbar {
    width: 10px; 
    height: 8px; 
    background-color: var(--content);
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 9em;
    border: 2px solid white;
    box-shadow: inset 1px 1px 10px gray;
  }