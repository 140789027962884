.wrapper5{
    height: 100vh;
    display: flex;
    justify-content: center;
    background-color: rgb(23, 23, 23);
    align-items: center;
}

.container5{
    width: 500px;
    height:  800px;
    border-radius: 15px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.container5 h1{
   color: #c9fd5d;
   font-size: 400px;
   font-family: 'Rampart One', cursive;
   z-index: 0;
   position: absolute;
   top: -20px;
   line-height: 440px;
   font-weight: 700;
   margin-top:20px;
}

.container5 h5{
    color: #c9fd5d;
    font-family: 'Montserrat', sans-serif;
  
}
.container5 img{
 z-index: 999;
}
 
.container5 button{
    background: black;
    height: 100px;
    width: 150px;
    color: #c9fd5d;
    border: none;
    border-radius: 5px;
    border: 1px solid #c9fd5d;
    height: 30px;
    font-size: 13px;
    cursor: pointer;
    margin: 30px;
 }


@media only screen and (max-width: 600px) {
    .container5{
        width:400px;
        height:  500px;
        border-radius: 15px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
  
    }
    .container5 h1{
        display: none;
     }
     .container5 img{
        width: 250px;
    }
}