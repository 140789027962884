.wrapper2{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 20px;
 
}

.container2{
    width: 400px;
    height: 450px;
    background: var(--tg-theme-secondary-bg-color);
    border-radius: 15px;
    padding: 10px;
    display: flex;
    justify-content: center;
}

.container2 form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 330px;
}


.container2 input{
    width: 330px;
    margin: 10px 0;
    height: 50px;
    border-radius: 10px;
    border: none;
    padding: 15px;
    border: 1px solid #c1c1c1;
    outline: none;
}

.btn2{
    width: 100%;
    height: 50px;
    margin: 20px 0 0 0;
    color: var(--tg-theme-button-text-color);
    background: var(--tg-theme-button-color);
    border: none;
    border-radius: 7px;
    border: 1px solid #1e1e1e;
    cursor: pointer;
    
}

.btn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0 20px 0;
    width: 100%;
    height: 50px;
    color: var(--tg-theme-text-color);
    border: none;
    border-radius: 7px;
    border: 1px solid #1e1e1e;
    cursor: pointer;
    border: 1px solid #c1c1c1;
}

.heading {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;

}

.heading button{
    width: 150px;
    border: none;
    border-radius: 5px;
    border: 1px solid #1e1e1e;
    height: 30px;
    font-size: 13px;
    cursor: pointer;
    background: var(--tg-theme-button-color);
    color: var(--tg-theme-button-text-color);
}

.choice {
    display: flex;
    justify-content: space-between;
  }
  .choice input[type=radio] {
     position: absolute;
     left: -99999px;
     transition: 1s;
  }
  .choice span {
    width: 80px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #c1c1c1;
    margin-right: 1px;
    cursor: pointer;
    border-radius: 7px;
    transition: 0.3s;
  }
  .choice input[type=radio]:checked + span {
    background: var(--tg-theme-button-color);
    border-color: var(--tg-theme-button-color);
  }

  .btn input[type=radio] {
    position: absolute;
    left: -99999px;
    transition: 1s;
 }

.dropdownContent {
   display: none;  
}

.show {
   display: flex;
    transition: 1s;
}

.button{
    font-size: 10px;
}

.span{
    font-size: 10px;
}

.choice input[type="checkbox"]:checked + span {
    background: var(--tg-theme-button-color);
    border-color: var(--tg-theme-button-color);
}

.mid{
    height: 70px;
}

.mid input:required:invalid ~ button.login {
    opacity: .65;
    pointer-events: none;
}

.opt{
    width: 330px;
    margin: 5px 0 15px 0;
    height: 50px;
    border-radius: 10px;
    border: none;
    padding: 15px;
    border: 1px solid #c1c1c1;
    outline: none;
    transition: 1s;
}


.modal{
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(3px);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s;
    z-index: 999;
}



.content{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px;
    border-radius: 14px;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(3px);
    transition: 0.3s;
    width: 300px;
    height: 300px;
    overflow: auto;
}

.content{
    font-size: 11px;
}


.content h1{
   margin-bottom: 30px;
   font-family: 'Montserrat', sans-serif;
}

.content h3{
    font-family: 'Montserrat', sans-serif;
}



 
.active{
        opacity: 1;
        pointer-events: all;
}

.box1{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    width: 100px;
    height: 100px;
    background: brown;
    border-radius: 10px;
    cursor: pointer;  
    transition: 0.3s;
    margin: 10px;
    background: var(--tg-theme-secondary-bg-color); 
}

.box1 img{
    margin-bottom: 10px;
    width: 45px;
    height: 45px;
}

.box1:hover{
    transform: scale(1.1);
}

.heading a{
    color: var(--tg-theme-text-color);
}

.foo {
    position: relative;
    box-sizing: border-box;
    color: #1e1e1e;
    font-size: 15px;
  }
  .foo input {
    padding-left: 2.3em;
  }
  .foo::before {
    content: '$';
    position: absolute;
    top: 25px; left: 20px;
    pointer-events: none;
  }
  
.rules h3{
    color: var(--tg-theme-text-color);
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    cursor: pointer;
}

.rules{
    height: 94px;
    overflow: auto;
    border-radius: 5px;
    padding: 5px;
}