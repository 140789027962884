.container1{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    height: fit-content;

}

.container1 a{
    text-decoration: none;
    color: var(--tg-theme-text-color);
    margin: 10px;
}


.paytype{
    display: flex;
    align-items: center;
    width: 340px;
    height: 100px;
    border: 1px solid white;
    border-radius: 14px;
    padding: 25px;
    transition: 0.5s;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background: var(--tg-theme-secondary-bg-color);
}
    
.paytype img{
    width: 50px;
}

.paytype:hover{
    transform: scale(1.1);
}

.paytype p{
 font-size: 18px;
 margin-left: 40px;
 margin-bottom: 10px;
 
}

.photo{
    width: 50px;
    margin: 14px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.photo span{
 font-size: 12px;
 margin-top: 10px;
}

